import { GLOBAL } from 'saddlebag-browser';

import type { AcornContext, ContextBody } from 'common/types/context';

export const isAcornRequest = (): boolean => {
  const $window = GLOBAL.getWindow();
  return $window.__internal?.isAcornRequest || false;
};

// It is just used to check if the context type: Acorn (AcornContext) or Banana (ContextBody | null)
export const isAcornContext = (
  context: AcornContext | ContextBody | null,
): context is AcornContext => isAcornRequest();
