import { GLOBAL } from 'saddlebag-browser';

import { isAcornRequest } from './utils/isAcornRequest';

/* There is a bug in eslint for dynamic imports. Please check https://github.com/typescript-eslint/typescript-eslint/issues/2108
We have 2 options, either to use .then() for the dynamic import or disable no-unused-expressions for entire file.  */
if (isAcornRequest()) {
  import(
    /* webpackChunkName: "Bootstrap-Acorn" */
    'webapp-acorn/src/bootstrap'
  )
    .then((bootstrap) => bootstrap.run())
    .catch((error) => {
      throw error;
    });
} else {
  // In production all uses of `translate()` have been replaced with strings in the build step when producing single locale bundles. In development where we have one single bundle we use the local translate function to read from local string maps.
  // See translations.md
  if (process.env.NODE_ENV === 'development') {
    const $window = GLOBAL.getWindow();
    // eslint-disable-next-line global-require
    $window.translate = require('./utils/translate.local').translate;
  }

  import(
    /* webpackChunkName: "Bootstrap" */
    './bootstrap'
  )
    .then((bootstrap) => bootstrap.run())
    .catch((error) => {
      throw error;
    });
}

export {};
